$(function () {
  if($('.editor').length > 0) {
    CKEDITOR.config.height = '500px';
    CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR;
    CKEDITOR.config.image_previewText = 'プレビューが表示されます';
    // CKEDITOR.config.contentsCss = '/application.css';
    $('.editor').each(function(index, _element){
      CKEDITOR.replace(`editor${index+1}`, {
        language: 'ja',
        filebrowserUploadUrl: '/admin/upload_fund_content_image',
        // startupMode: 'source',
      });
    });
  }
});
