import moment from 'moment';
moment.locale('ja');

$(function () {
  let dateNow = new Date();
  // $('.datetimepicker').datetimepicker({
  //   locale: 'ja',
  //   format: 'YYYY/MM/DD HH:mm',
  //   defaultDate: moment(dateNow).hours(0).minutes(0).seconds(0).milliseconds(0)
  // });
  //
  // $('.datepicker').datetimepicker({
  //   locale: 'ja',
  //   format: 'YYYY/MM/DD',
  //   // defaultDate: moment(dateNow).hours(0).minutes(0).seconds(0).milliseconds(0)
  //   defaultDate: null
  // });
  //
  // $('.datepicker-search').datetimepicker({
  //   locale: 'ja',
  //   format: 'YYYY/MM/DD'
  // });

  $('.datepicker-year-month').datetimepicker({
    locale: 'ja',
    format: 'YYYY/MM',
    defaultDate: moment(dateNow).hours(0).minutes(0).seconds(0).milliseconds(0)
  });
});
