import moment from 'moment'
import 'moment/locale/ja'

document.addEventListener("turbo:load", function() {
  moment.locale('ja');
  $('.js-daterangepicker').daterangepicker({
    autoUpdateInput: false,
    timePicker: true,
    locale: {
      format: 'YYYY-MM-DD HH:mm',
      applyLabel: "OK",
      cancelLabel: "クリア"
    }
  });

  // Applyボタンが押された時にformに日時の範囲をsetする
  // See http://www.daterangepicker.com/#ex4
  $('.js-daterangepicker').on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('YYYY-MM-DD HH:mm') + ' - ' + picker.endDate.format('YYYY-MM-DD HH:mm'))
  });

  // Clearボタンが押された時にformをclearする
  // See http://www.daterangepicker.com/#ex4
  $('.js-daterangepicker').on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
  });

  $('.datepicker').daterangepicker({
    autoUpdateInput: false,
    singleDatePicker: true,
    showDropdowns: true,
    minYear: 1901,
    timePicker: false,
    locale: {
      format: 'YYYY-MM-DD',
      applyLabel: "OK",
      cancelLabel: "クリア",
      daysOfWeek: [ "日", "月", "火", "水", "木", "金", "土" ],
      monthNames: [ "1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月" ],
    }
  });

  // Applyボタンが押された時にformに日時の範囲をsetする
  // See http://www.daterangepicker.com/#ex4
  $('.datepicker').on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.endDate.format('YYYY-MM-DD'))
  });

  // Clearボタンが押された時にformをclearする
  // See http://www.daterangepicker.com/#ex4
  $('.datepicker').on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
  });
})
