// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
// import "./controllers"

import './common/jquery'
import './common/jquery-ui/jquery-ui'
// import 'jquery'
// import 'jquery-ui'
// import 'jquery-ui/ui/widgets/sortable'
// import 'jquery.cookie'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap-daterangepicker/daterangepicker'
import 'eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker'
import 'select2'
// import 'select2/dist/js/i18n/ja'

// common
import './common/util'
import './common/daterangepicker'
import './common/datetimepicker'
import './common/autonumeric'
import './common/ckeditor'
import './common/zipcode'

// adminlte3
import './plugins/adminlte/dist/js/adminlte.min'

import './admin/sales_results/edit'
