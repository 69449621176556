document.addEventListener("turbo:load", function() {
  $('#js-staffs-dropdown select').change(function() {
    if ($(this).val() !== "") {
      $('#js-help-staffs-dropdown').hide()
    } else {
      $('#js-help-staffs-dropdown').show()
    }
  });

  $('#js-help-staffs-dropdown select').change(function() {
    if ($(this).val() !== "") {
      $('#js-staffs-dropdown').hide()
    } else {
      $('#js-staffs-dropdown').show()
    }
  })

  if ($('#js-staffs-dropdown select').val() !== '') {
    $('#js-help-staffs-dropdown').hide()
  } else if ($('#js-help-staffs-dropdown select').val() !== '') {
    $('#js-staffs-dropdown').hide()
  }
})
